
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormTextarea from "@/components/form/Textarea.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormSelect, { CFormSelectOption } from "@/components/form/Select.vue";

import PInputSwitch from "primevue/inputswitch";
import PProgressBar from "primevue/progressbar";

import { ProcedureForm } from "@/store/procedure/types";
import { formatDate, formatDateTimeEn, isEditFormByRouteName } from "@/libs/utils";

const VProcedureForm = defineComponent({
  name: "VProcedureForm",
  components: { CModal, CFormInputText, CFormTextarea, CFormSelect, PInputSwitch, PProgressBar },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} procedimento | obmed` });

    const procedure = computed(() => store.state.procedure.current);
    const procedureTypes = computed(() => store.state.procedure.types);
    const procedureTypeOptions = computed<CFormSelectOption[]>(() =>
      procedureTypes.value.map((item) => ({ label: item.ie_tipo_procedimento, value: item.id }))
    );
    const form = reactive<ProcedureForm>({
      cd_procedimento: "",
      cd_tipo_procedimento: null,
      nm_procedimento: "",
      ds_procedimento: "",
      dt_vigencia_inicial: formatDate(new Date().toISOString()),
      ie_status: true,
      procedimento_estabelecimento: [],
    });
    const loading = reactive({ procedure: false, submit: false });

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const dt_vigencia_inicial = formatDateTimeEn(form.dt_vigencia_inicial, "00:00");
      const response = isEditForm
        ? await store.dispatch("updateProcedure", { _id, form: { ...form, dt_vigencia_inicial } })
        : await store.dispatch("createProcedure", { form: { ...form, dt_vigencia_inicial } });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getProcedures");

        if (route.name?.toString().includes("establishment"))
          router.replace({
            name: "establishment-procedure-add",
            query: { ...route.query, cbhpm: response?.data?.cd_procedimento },
          });
        else router.replace({ name: "procedure-list" });
      }
    }

    function handleProcedureMask(value: string) {
      return value?.replace(/\D/g, "");
    }

    async function getProcedure() {
      loading.procedure = true;
      await Promise.allSettled([store.dispatch("getProcedure", { _id }), store.dispatch("getProcedureTypes")]);
      loading.procedure = false;
    }

    async function handleApplyForm() {
      await getProcedure();

      form.cd_tipo_procedimento = procedure.value?.cd_tipo_procedimento || null;
      form.nm_procedimento = procedure.value?.nm_procedimento || "";
      form.cd_procedimento = procedure.value?.cd_procedimento || "";
      form.ds_procedimento = procedure.value?.ds_procedimento || "";
      form.dt_vigencia_inicial = formatDate(procedure.value?.dt_vigencia_inicial) || "";
      form.ie_status = procedure.value?.ie_status ?? true;
      form.procedimento_estabelecimento = procedure.value?.procedimento_estabelecimento || [];
    }

    if (isEditForm) handleApplyForm();
    else Promise.allSettled([store.dispatch("getProcedureTypes")]);

    return {
      ...{ form, procedureTypeOptions, loading, isEditForm },
      ...{ handleClose, onSubmit, handleProcedureMask },
    };
  },
});

export default VProcedureForm;
